import React from 'react';
import { useSelector } from 'react-redux';
import { IStateType } from '../../../store/models/root.interface';
import Loader from 'react-loader-spinner';
import { Translate } from '../../../utils/Translate';

const WaitConfirmation: React.FC = () => {
  const loadingAssignTrip = useSelector(
    (state: IStateType) => state.assigntrip.loadingAssignTrip
  );

  const t = new Translate()
  return (
    <div>
      {loadingAssignTrip === false ? (
        <div className='container d-flex flex-column justify-content-center align-items-center'>
          <div
            className='d-flex justify-content-center align-items-center rounded m-2 p-4'
            style={{ backgroundColor: 'rgb(44, 55, 94)' }}
          >
            <p
              style={{
                color: 'white',
                textAlign: 'center',
                textShadow:
                  '0px 4px 3px rgba(0,0,0,0.4), 0px 8px 13px rgba(0,0,0,0.1), 0px 18px 23px rgba(0,0,0,0.1)',
                fontSize: '0.8rem',
              }}
            >
              {t.translate("Your Request is getting confirmed by IVY-Transportation Department.")}
              <br />
              {t.translate("You will be notifed once the process is finished.")} <br />
              {t.translate("For any inquires, feel free to call the Ivy-Transportation Department on")}
              <a href="tel:+201555559447">+20 155 555 9447</a>
            </p>
          </div>
        </div>
      ) : (
        <div className='d-flex justify-content-center align-items-center mt-5'>
          <Loader type='ThreeDots' color='#00BFFF' height={100} width={100} />
        </div>
      )}
    </div>
  );
};

export default WaitConfirmation;
